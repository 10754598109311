@import "styles/variables.scss";

.Media-section {
  padding: 160px 0 160px 0;
  background-color: $turquoise-blue;

  .section-name-container {
    margin-bottom: 50px;
  }

  .custom-btn {
    width: 200px;
  }
}
