@import "styles/mixin.scss";

.Media-item {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid white;

  .custom-flex-container {
    display: flex;
  }

  .info-container {
    color: white;
    flex: 1;
    margin-right: 18px;

    .title-container {
      span {
        font-weight: bold;
      }
    }

    .date-container {
      margin-bottom: 12px;
      span {
        font-size: 0.8rem;
      }
    }
    .text-container {
      span {
        font-size: 0.8rem;
      }
    }
  }

  .photo {
    width: 170px;
    height: 150px;
  }

  @include respond-below(sm) {
    .custom-flex-container {
      display: block;
    }

    .photo {
      margin-top: 14px;
    }
  }
}
