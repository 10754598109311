@import "styles/variables.scss";
.About-us {
  padding: 180px 0 140px 0;
  background-color: $turquoise-blue;
  color: white;
  text-align: center;

  .title-container {
    margin-bottom: 30px;
    span {
      font-size: 2.5rem;
    }
  }
  .description-container {
    display: inline-block;
    max-width: 510px;
    span {
      font-size: 1.2rem;
    }
  }
}
