@import "styles/variables.scss";
@import "styles/mixin.scss";

.Answer-item {
  padding: 24px 0px 24px 0px;
  border-bottom: 1px solid #e2e2e2;

  &.selected {
    border-bottom: 1px solid #05acd152;
  }

  .answer-item-question-container {
    font-weight: bold;
    font-size: 1.1rem;
    color: #6e6e6e;
    cursor: pointer;
  }

  .answer-item-question-container.selected {
    color: $turquoise-blue;
  }
  .answer-item-answer-container {
    margin-top: 20px;
    white-space: pre-line;
    color: #6e6e6e;

    a {
      color: $turquoise-blue !important;
    }

    table {
      width: 100%;
      border: 1px solid #bbbbbb;

      th,
      td {
        padding: 12px;
        border: 1px solid #bbbbbb;
      }

      @include respond-below(sm) {
        th,
        td {
          font-size: 0.7rem;
          padding: 4px;
        }
      }
    }
  }
}
