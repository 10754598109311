.Categories {
  padding: 80px 48px 80px 48px;
  text-align: center;
  background-color: white;
  height: 100%;
  color: #6e6e6e;

  .description {
    margin-bottom: 60px;
    span {
      font-size: 1.2rem;
    }
  }

  .category-item {
    border: 1px solid white;
    margin-bottom: 24px;
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    text-align: left;
  }
}
