@import "styles/variables.scss";
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+KR:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Questrial");

html {
  font-size: 18px;
}

body {
  font-family: "Lato", "Noto Sans KR", sans-serif;
  line-height: normal;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: $black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
