@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+KR:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Questrial);
.About-us {
  padding: 180px 0 140px 0;
  background-color: #05aad1;
  color: white;
  text-align: center; }
  .About-us .title-container {
    margin-bottom: 30px; }
    .About-us .title-container span {
      font-size: 2.5rem; }
  .About-us .description-container {
    display: inline-block;
    max-width: 510px; }
    .About-us .description-container span {
      font-size: 1.2rem; }

.Event-item {
  padding: 0 14px 28px 14px; }
  .Event-item .content {
    position: relative;
    height: 325px;
    border: 1px solid #05aad1; }
    .Event-item .content .image {
      height: 250px; }
    .Event-item .content .info-container {
      padding: 12px;
      color: #05aad1; }
      .Event-item .content .info-container .sub-title-container span {
        font-size: 0.8rem; }
      .Event-item .content .info-container .title-container {
        margin-top: 4px; }
    .Event-item .content .overlay {
      position: absolute;
      top: 0;
      padding: 8px;
      width: 100%;
      left: 0;
      color: white;
      font-size: 0.8rem; }
      .Event-item .content .overlay.past {
        background-color: rgba(63, 63, 63, 0.867); }
      .Event-item .content .overlay.upcoming {
        background-color: #05aad1; }

.Events-section {
  padding: 160px 0 160px 0;
  background-color: white; }
  .Events-section .section-name-container {
    margin-bottom: 50px; }
  .Events-section .slick-prev:before,
  .Events-section .slick-next:before {
    color: #05aad1; }

.Media-item {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid white; }
  .Media-item .custom-flex-container {
    display: flex; }
  .Media-item .info-container {
    color: white;
    flex: 1 1;
    margin-right: 18px; }
    .Media-item .info-container .title-container span {
      font-weight: bold; }
    .Media-item .info-container .date-container {
      margin-bottom: 12px; }
      .Media-item .info-container .date-container span {
        font-size: 0.8rem; }
    .Media-item .info-container .text-container span {
      font-size: 0.8rem; }
  .Media-item .photo {
    width: 170px;
    height: 150px; }
  @media (max-width: 767px) {
    .Media-item .custom-flex-container {
      display: block; }
    .Media-item .photo {
      margin-top: 14px; } }

.Media-section {
  padding: 160px 0 160px 0;
  background-color: #05aad1; }
  .Media-section .section-name-container {
    margin-bottom: 50px; }
  .Media-section .custom-btn {
    width: 200px; }

.Talk-item {
  color: black;
  padding: 0 14px 28px 14px; }
  .Talk-item .content {
    position: relative;
    background-color: white;
    height: 250px;
    padding: 28px; }
    .Talk-item .content .deco {
      width: 50px;
      height: 3px;
      background-image: -webkit-linear-gradient(left, #1b8e7d 50%, #e84c06 50%);
      background-image: linear-gradient(90deg, #1b8e7d 50%, #e84c06 50%);
      margin-bottom: 18px; }
    .Talk-item .content .title-container span {
      font-size: 1.2rem; }
    .Talk-item .content .bottom-container {
      position: absolute;
      text-align: center;
      width: 100%;
      bottom: 20px;
      left: 0; }
      .Talk-item .content .bottom-container .date-container {
        margin-bottom: 8px; }
        .Talk-item .content .bottom-container .date-container span {
          font-size: 0.8rem; }
      .Talk-item .content .bottom-container .logo-container img {
        width: 80px;
        height: auto;
        display: inline-block; }

.Talks-section {
  padding: 160px 0 160px 0;
  background-color: #05aad1;
  color: white; }
  .Talks-section .section-name-container {
    margin-bottom: 50px; }

.Categories {
  padding: 80px 48px 80px 48px;
  text-align: center;
  background-color: white;
  height: 100%;
  color: #6e6e6e; }
  .Categories .description {
    margin-bottom: 60px; }
    .Categories .description span {
      font-size: 1.2rem; }
  .Categories .category-item {
    border: 1px solid white;
    margin-bottom: 24px;
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    text-align: left; }

.Answer-item {
  padding: 24px 0px 24px 0px;
  border-bottom: 1px solid #e2e2e2; }
  .Answer-item.selected {
    border-bottom: 1px solid #05acd152; }
  .Answer-item .answer-item-question-container {
    font-weight: bold;
    font-size: 1.1rem;
    color: #6e6e6e;
    cursor: pointer; }
  .Answer-item .answer-item-question-container.selected {
    color: #05aad1; }
  .Answer-item .answer-item-answer-container {
    margin-top: 20px;
    white-space: pre-line;
    color: #6e6e6e; }
    .Answer-item .answer-item-answer-container a {
      color: #05aad1 !important; }
    .Answer-item .answer-item-answer-container table {
      width: 100%;
      border: 1px solid #bbbbbb; }
      .Answer-item .answer-item-answer-container table th,
      .Answer-item .answer-item-answer-container table td {
        padding: 12px;
        border: 1px solid #bbbbbb; }
      @media (max-width: 767px) {
        .Answer-item .answer-item-answer-container table th,
        .Answer-item .answer-item-answer-container table td {
          font-size: 0.7rem;
          padding: 4px; } }

.Quick-answers {
  padding: 80px 48px 80px 48px;
  background-color: white; }
  .Quick-answers .answer-container {
    padding: 0px 0px 60px 0px; }
    .Quick-answers .answer-container .category-title-container {
      margin-bottom: 12px; }
      .Quick-answers .answer-container .category-title-container span {
        color: #05aad1;
        font-size: 1.4rem;
        font-weight: bold; }
  @media (max-width: 767px) {
    .Quick-answers {
      padding: 80px 24px 80px 24px; } }

.Search-resources-section {
  padding: 40px 0px 40px 0px;
  display: flex;
  align-items: center; }
  .Search-resources-section .section-name-container {
    margin-bottom: 60px; }
  .Search-resources-section .search-resource-title {
    font-weight: bold;
    font-size: 1.5rem;
    color: white;
    padding: 0px 48px 0px 48px; }
  .Search-resources-section .select-wrapper {
    padding: 0px 48px 0px 48px;
    margin-left: auto; }
  @media (max-width: 767px) {
    .Search-resources-section .search-resource-title {
      padding: 0px 24px 0px 24px; }
    .Search-resources-section .select-wrapper {
      display: none; } }
  .Search-resources-section .select-container {
    width: 400px; }
    .Search-resources-section .select-container .custom-select__control {
      border: none;
      height: 50px;
      padding-left: 12px; }
    .Search-resources-section .select-container .custom-select__indicator {
      color: #05aad1; }
    .Search-resources-section .select-container .custom-select__menu {
      margin-top: 4px; }
      .Search-resources-section .select-container .custom-select__menu .custom-select__menu-list {
        color: #05aad1;
        text-align: left; }

.Faq .faq-content-container {
  display: flex;
  min-height: 100vh;
  background-color: white; }
  .Faq .faq-content-container .faq-left {
    width: 360px;
    border-right: 1px solid #c2d1d3; }
  @media (max-width: 767px) {
    .Faq .faq-content-container .faq-left {
      display: none; } }
  .Faq .faq-content-container .faq-right {
    flex: 1 1;
    min-height: 100vh; }

.Footer {
  background-color: #123447;
  color: white;
  padding: 32px 0 171px 0;
  font-size: 0.77rem; }
  .Footer .link-icon-container {
    text-align: right; }
    .Footer .link-icon-container .link-icon {
      cursor: pointer; }
    @media (max-width: 767px) {
      .Footer .link-icon-container {
        text-align: left;
        margin-top: 32px; } }
  .Footer .link-container {
    font-size: 0.88rem;
    margin-top: 44px;
    font-weight: bold; }
    .Footer .link-container .link-item-col {
      display: inline-block;
      vertical-align: top;
      margin-right: 40px; }
      .Footer .link-container .link-item-col .link-item:hover {
        cursor: pointer; }
    @media (max-width: 767px) {
      .Footer .link-container {
        margin-top: 72px; }
      .Footer .link-container .link-item-col {
        display: block;
        margin-bottom: 28px; } }

.lang-popover {
  background-color: transparent !important;
  border-color: transparent !important;
  right: 0;
  width: 70px;
  cursor: pointer;
  margin-top: 2px !important; }
  .lang-popover .popover-body {
    margin-right: 5px;
    color: white;
    padding: 0;
    text-align: right; }
  .lang-popover .eng-text {
    padding-right: 3px; }
  .lang-popover.white-header .item-name {
    width: 70px;
    color: #05aad1; }

.Header {
  padding: 0 24px 0 24px;
  height: 76px;
  z-index: 10;
  position: fixed;
  width: 100%;
  background-color: #05aad1; }
  .Header .language-icon {
    font-size: 1.1rem;
    margin-right: 6px; }
  .Header .menu-item {
    color: white;
    margin-left: 32px;
    cursor: pointer; }
    .Header .menu-item .paper-btn {
      width: 148px;
      height: 40px;
      font-weight: 400; }
    .Header .menu-item img.globe {
      margin-top: 2px;
      margin-right: 4px;
      width: 25px;
      height: 25px; }
    .Header .menu-item img.down {
      margin-top: 2px;
      width: 20px; }
  .Header .divider {
    width: 1px;
    background-color: white;
    height: 18px;
    margin-left: 32px; }
  .Header.white-header {
    background-color: white; }
    .Header.white-header.transition {
      -webkit-transition: all 0.2s ease-in;
      transition: all 0.2s ease-in; }
    .Header.white-header .menu-item {
      color: #05aad1; }
    .Header.white-header .divider {
      background-color: #05aad1; }

.header-menu-popover {
  border: 0 !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  margin-top: 0 !important; }
  .header-menu-popover .popover-body {
    padding: 0 12px 12px 12px;
    color: white;
    font-weight: 400; }
    .header-menu-popover .popover-body .arrow-container {
      vertical-align: top; }
    .header-menu-popover .popover-body .menu-drop-item-container {
      margin-top: 8px; }
      .header-menu-popover .popover-body .menu-drop-item-container .menu-drop-item {
        margin-top: 4px;
        cursor: pointer; }
        .header-menu-popover .popover-body .menu-drop-item-container .menu-drop-item.selected {
          font-weight: bold; }
  .header-menu-popover.white-menu {
    background-color: white !important; }
    .header-menu-popover.white-menu .popover-body {
      color: #05aad1; }

.header-menu-modal {
  margin: 0 !important;
  max-width: 100% !important; }
  .header-menu-modal .modal-content {
    border-radius: 0;
    border: 0; }
    .header-menu-modal .modal-content .modal-body {
      padding: 0; }
    .header-menu-modal .modal-content .menu-modal {
      width: 100vw;
      height: 100vh;
      background-color: #05aad1;
      color: white;
      text-align: center;
      padding: 80px 0 80px 0; }
      .header-menu-modal .modal-content .menu-modal .close-btn {
        position: absolute;
        right: 18px;
        top: 25px;
        cursor: pointer; }
      .header-menu-modal .modal-content .menu-modal .menu-item {
        margin-top: 25px; }
        .header-menu-modal .modal-content .menu-modal .menu-item .item-name {
          font-size: 1rem;
          cursor: pointer; }
        .header-menu-modal .modal-content .menu-modal .menu-item .paper-btn {
          width: 148px;
          height: 40px; }
        .header-menu-modal .modal-content .menu-modal .menu-item .menu-drop-item {
          font-weight: 1.125rem;
          margin-bottom: 8px;
          cursor: pointer; }
      .header-menu-modal .modal-content .menu-modal .divider-vertical {
        display: inline-block;
        margin-top: 25px;
        width: 200px;
        height: 1px;
        background-color: white; }

.Contact-us-section {
  padding: 102px 0 112px 0;
  background-color: white; }
  .Contact-us-section .section-name-container {
    margin-bottom: 60px; }
    .Contact-us-section .section-name-container .section-description-container {
      max-width: 500px; }
  .Contact-us-section .contact-form {
    margin: 0 auto;
    max-width: 532px; }
    .Contact-us-section .contact-form input {
      height: 48px;
      border-radius: 2px; }
    .Contact-us-section .contact-form input:focus,
    .Contact-us-section .contact-form textarea:focus {
      outline: none !important;
      -webkit-appearance: none;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      border: 1px solid #05aad1; }
    .Contact-us-section .contact-form input.error,
    .Contact-us-section .contact-form textarea.error {
      border: 1px solid #d1050a; }
    .Contact-us-section .contact-form textarea {
      resize: none;
      border-radius: 2px; }
    .Contact-us-section .contact-form ::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #c2d1d3;
      opacity: 1;
      /* Firefox */ }
    .Contact-us-section .contact-form ::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #c2d1d3;
      opacity: 1;
      /* Firefox */ }
    .Contact-us-section .contact-form ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #c2d1d3;
      opacity: 1;
      /* Firefox */ }
    .Contact-us-section .contact-form :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #c2d1d3; }
    .Contact-us-section .contact-form ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #c2d1d3; }
    .Contact-us-section .contact-form .submit-btn-container {
      margin-top: 52px;
      text-align: center; }
      .Contact-us-section .contact-form .submit-btn-container .submit-btn {
        background-color: #05aad1;
        border-radius: 2px;
        width: 196px;
        height: 56px;
        align-items: center !important;
        justify-content: center !important;
        display: inline-flex !important;
        color: white;
        cursor: pointer;
        font-weight: bold; }

.Feature-section {
  padding: 160px 0 160px 0;
  background-color: white; }
  .Feature-section .section-name-container {
    margin-bottom: 50px; }
  .Feature-section .feature-item {
    width: 300px;
    margin: 50px 25px 50px 25px;
    vertical-align: top;
    text-align: center; }
    @media screen and (max-width: 450px) {
      .Feature-section .feature-item {
        margin: 0 0 80px 0; } }
    .Feature-section .feature-item .feature-item-title-container {
      margin-bottom: 12px; }
      .Feature-section .feature-item .feature-item-title-container .title {
        font-size: 1.55rem; }
    .Feature-section .feature-item .description {
      line-height: 1.22;
      color: #434343; }

.Logo-section {
  background-color: #05aad1;
  height: calc(100vh - 76px);
  min-height: 600px;
  overflow: visible;
  text-align: center;
  color: white;
  background-image: url(/static/media/building.87222bae.png);
  background-size: auto 588px;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-attachment: fixed;
  position: relative; }
  @media (max-width: 767px) {
    .Logo-section {
      background-attachment: scroll; } }
  .Logo-section .logo-content {
    margin-bottom: 100px;
    padding: 0 15px 0 15px; }
    .Logo-section .logo-content .title {
      font-size: 3.77rem;
      font-family: "Questrial", sans-serif;
      margin-bottom: 15px; }
    .Logo-section .logo-content .description {
      font-size: 1.5rem;
      margin-bottom: 60px; }
    .Logo-section .logo-content .paper-btn {
      height: 56px;
      width: 196px;
      display: inline-block;
      margin: 8px; }
  .Logo-section .graph-background {
    position: absolute;
    height: 310px;
    width: 100%;
    bottom: 0;
    background-image: url(/static/media/graph.038d3be2.png);
    background-position: bottom center;
    background-repeat: no-repeat; }
  .Logo-section .computer-continer {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: -90px; }
    .Logo-section .computer-continer img {
      width: 459px;
      height: auto; }
    @media screen and (max-width: 500px) {
      .Logo-section .computer-continer {
        bottom: -35px; }
        .Logo-section .computer-continer img {
          width: 250px;
          height: auto; } }

.Platform-section {
  padding: 84px 0 91px 0;
  background-color: #05aad1;
  color: white;
  text-align: center; }
  .Platform-section .section-name-container {
    margin-bottom: 97px; }
  .Platform-section .platform-item-container {
    margin: 0 -60px 0 -60px; }
    .Platform-section .platform-item-container .platform-item {
      width: 280px;
      margin: 0 60px 80px 60px;
      vertical-align: top; }
      .Platform-section .platform-item-container .platform-item .platform-item-image-container {
        position: relative; }
      .Platform-section .platform-item-container .platform-item .description {
        font-size: 1.55rem; }
      .Platform-section .platform-item-container .platform-item .description2 {
        line-height: 1.22; }
    @media screen and (max-width: 450px) {
      .Platform-section .platform-item-container {
        margin: 0; }
        .Platform-section .platform-item-container .platform-item {
          margin: 0 0 80px 0; } }
  .Platform-section .architecture-container {
    margin-top: 112px; }
    .Platform-section .architecture-container .architecture-img {
      width: 100%;
      height: auto; }
  .Platform-section .button-container {
    margin-top: 112px; }
    .Platform-section .button-container .custom-btn {
      width: 280px;
      height: 56px;
      margin: 0 12px 0 12px; }
    @media (max-width: 991px) {
      .Platform-section .button-container .custom-btn {
        display: flex !important;
        margin: 0 auto;
        margin-bottom: 12px; } }


#Not-found {
  position: relative;
  height: calc(100vh - 80px); }
  #Not-found .not-found {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  #Not-found .not-found {
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center; }
  #Not-found .not-found .not-found-404 {
    position: relative;
    height: 240px; }
  #Not-found .not-found .not-found-404 h1 {
    font-family: "Montserrat", sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 252px;
    font-weight: 900;
    margin: 0px;
    color: #262626;
    text-transform: uppercase;
    letter-spacing: -40px;
    margin-left: -20px; }
  #Not-found .not-found .not-found-404 h1 > span {
    text-shadow: -8px 0px 0px #fff; }
  #Not-found .not-found .not-found-404 h3 {
    font-family: "Cabin", sans-serif;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #262626;
    margin: 0px;
    letter-spacing: 3px;
    padding-left: 6px; }
  #Not-found .not-found h2 {
    font-family: "Cabin", sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #000;
    margin-top: 0px;
    margin-bottom: 25px; }
  @media only screen and (max-width: 767px) {
    #Not-found .not-found .not-found-404 {
      height: 200px; }
    #Not-found .not-found .not-found-404 h1 {
      font-size: 200px; } }
  @media only screen and (max-width: 480px) {
    #Not-found .not-found .not-found-404 {
      height: 162px; }
    #Not-found .not-found .not-found-404 h1 {
      font-size: 162px;
      height: 150px;
      line-height: 162px; }
    #Not-found .not-found h2 {
      font-size: 16px; } }

.App .Content {
  padding-top: 76px;
  min-height: 100vh;
  background-color: #05aad1; }

html {
  font-size: 18px; }

body {
  font-family: "Lato", "Noto Sans KR", sans-serif;
  line-height: normal;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.custom-btn {
  border: 1px solid #05aad1;
  line-height: 40px;
  border-radius: 2px;
  z-index: 1;
  color: #05aad1;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  align-items: center !important;
  justify-content: center !important;
  display: inline-flex !important; }
  .custom-btn:hover {
    background-color: rgba(5, 170, 209, 0.12); }
  .custom-btn.reverse {
    color: white;
    border: 1px solid white; }
    .custom-btn.reverse:hover {
      background-color: rgba(255, 255, 255, 0.24); }

a {
  color: inherit; }
  a:hover {
    color: inherit;
    text-decoration: none; }

.popover {
  font-family: "Lato", sans-serif;
  line-height: normal; }

.section-name-container {
  text-align: center; }
  .section-name-container .section-title-container .section-title {
    color: #05aad1;
    font-size: 2.22rem; }
    .section-name-container .section-title-container .section-title.reverse {
      color: white; }
  .section-name-container .section-description-container {
    max-width: 592px;
    margin: 0 auto;
    margin-top: 28px; }
    .section-name-container .section-description-container .section-description {
      font-size: 1.22rem;
      color: #05aad1; }
      .section-name-container .section-description-container .section-description.reverse {
        color: white; }
  .section-name-container.reverse .section-index {
    color: white; }
  .section-name-container.reverse .section-deco {
    background-color: white; }
  .section-name-container.reverse .section-title {
    color: white; }
  .section-name-container.reverse .section-description {
    color: white; }

.loader {
  display: inline-block;
  border: 3px solid #05aad1;
  border-top: 3px solid transparent;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite; }
  .loader.reverse {
    border: 3px solid white;
    border-top: 3px solid transparent; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

