@import "styles/variables.scss";

.lang-popover {
  background-color: transparent !important;
  border-color: transparent !important;
  right: 0;
  width: 70px;
  cursor: pointer;
  margin-top: 2px !important;
  .popover-body {
    margin-right: 5px;
    color: white;
    padding: 0;
    text-align: right;
  }
  .eng-text {
    padding-right: 3px;
  }

  &.white-header {
    .item-name {
      width: 70px;
      color: $turquoise-blue;
    }
  }
}

.Header {
  padding: 0 24px 0 24px;
  height: $header-height;
  z-index: 10;
  position: fixed;
  width: 100%;
  background-color: $turquoise-blue;

  .language-icon {
    font-size: 1.1rem;
    margin-right: 6px;
  }

  .menu-item {
    color: white;
    margin-left: 32px;
    cursor: pointer;

    .paper-btn {
      width: 148px;
      height: 40px;
      font-weight: 400;
    }

    img.globe {
      margin-top: 2px;
      margin-right: 4px;
      width: 25px;
      height: 25px;
    }

    img.down {
      margin-top: 2px;
      width: 20px;
    }
  }

  .divider {
    width: 1px;
    background-color: white;
    height: 18px;
    margin-left: 32px;
  }

  &.white-header {
    background-color: white;
    &.transition {
      transition: all 0.2s ease-in;
    }

    .menu-item {
      color: $turquoise-blue;
    }

    .divider {
      background-color: $turquoise-blue;
    }
  }
}

.header-menu-popover {
  border: 0 !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  margin-top: 0 !important;

  .popover-body {
    padding: 0 12px 12px 12px;
    color: white;
    font-weight: 400;

    .arrow-container {
      vertical-align: top;
    }

    .menu-drop-item-container {
      margin-top: 8px;
      .menu-drop-item {
        margin-top: 4px;
        cursor: pointer;

        &.selected {
          font-weight: bold;
        }
      }
    }
  }

  &.white-menu {
    background-color: white !important;
    .popover-body {
      color: $turquoise-blue;
    }
  }
}

.header-menu-modal {
  margin: 0 !important;
  max-width: 100% !important;

  .modal-content {
    border-radius: 0;
    border: 0;

    .modal-body {
      padding: 0;
    }
    .menu-modal {
      width: 100vw;
      height: 100vh;
      background-color: $turquoise-blue;
      color: white;
      text-align: center;
      padding: 80px 0 80px 0;

      .close-btn {
        position: absolute;
        right: 18px;
        top: 25px;
        cursor: pointer;
      }
      .menu-item {
        margin-top: 25px;
        .item-name {
          font-size: 1rem;
          cursor: pointer;
        }
        .paper-btn {
          width: 148px;
          height: 40px;
        }
        .menu-drop-item {
          font-weight: 1.125rem;
          margin-bottom: 8px;
          cursor: pointer;
        }
      }

      .divider-vertical {
        display: inline-block;
        margin-top: 25px;
        width: 200px;
        height: 1px;
        background-color: white;
      }
    }
  }
}
