@import "styles/variables.scss";
@import "styles/mixin.scss";

.Quick-answers {
  padding: 80px 48px 80px 48px;
  background-color: white;

  .answer-container {
    padding: 0px 0px 60px 0px;

    .category-title-container {
      margin-bottom: 12px;
      span {
        color: $turquoise-blue;
        font-size: 1.4rem;
        font-weight: bold;
      }
    }
  }
  @include respond-below(sm) {
    & {
      padding: 80px 24px 80px 24px;
    }
  }
}
