@import "styles/variables.scss";

.Feature-section {
  padding: 160px 0 160px 0;
  background-color: white;

  .section-name-container {
    margin-bottom: 50px;
  }

  .feature-item {
    width: 300px;
    margin: 50px 25px 50px 25px;
    vertical-align: top;
    text-align: center;

    @media screen and (max-width: 450px) {
      margin: 0 0 80px 0;
    }

    .feature-item-title-container {
      margin-bottom: 12px;
      .title {
        font-size: 1.55rem;
      }
    }

    .description {
      line-height: 1.22;
      color: #434343;
    }
  }
}
