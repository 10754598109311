@import "styles/variables.scss";

.Contact-us-section {
  padding: 102px 0 112px 0;
  background-color: white;

  .section-name-container {
    margin-bottom: 60px;
    .section-description-container {
      max-width: 500px;
    }
  }

  .contact-form {
    margin: 0 auto;
    max-width: 532px;

    input {
      height: 48px;
      border-radius: 2px;
    }

    input:focus,
    textarea:focus {
      outline: none !important;
      -webkit-appearance: none;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      border: 1px solid $turquoise-blue;
    }

    input.error,
    textarea.error {
      border: 1px solid #d1050a;
    }

    textarea {
      resize: none;
      border-radius: 2px;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $light-blue-grey;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $light-blue-grey;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $light-blue-grey;
    }

    .submit-btn-container {
      margin-top: 52px;
      text-align: center;
      .submit-btn {
        background-color: $turquoise-blue;
        border-radius: 2px;
        width: 196px;
        height: 56px;
        align-items: center !important;
        justify-content: center !important;
        display: inline-flex !important;
        color: white;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}
