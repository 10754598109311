.Talk-item {
  color: black;
  padding: 0 14px 28px 14px;

  .content {
    position: relative;
    background-color: white;
    height: 250px;
    padding: 28px;

    .deco {
      width: 50px;
      height: 3px;
      background-image: linear-gradient(90deg, #1b8e7d 50%, #e84c06 50%);
      margin-bottom: 18px;
    }

    .title-container {
      span {
        font-size: 1.2rem;
      }
    }

    .bottom-container {
      position: absolute;
      text-align: center;
      width: 100%;
      bottom: 20px;
      left: 0;

      .date-container {
        margin-bottom: 8px;
        span {
          font-size: 0.8rem;
        }
      }

      .logo-container {
        img {
          width: 80px;
          height: auto;
          display: inline-block;
        }
      }
    }
  }
}
