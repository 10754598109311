@import "styles/variables.scss";
@import "styles/mixin.scss";

.Logo-section {
  background-color: $turquoise-blue;
  height: calc(100vh - #{$header-height});
  min-height: 600px;
  overflow: visible;
  text-align: center;
  color: white;
  background-image: url(./img/building.png);
  background-size: auto 588px;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-attachment: fixed;
  position: relative;

  @include respond_below(sm) {
    & {
      background-attachment: scroll;
    }
  }

  .logo-content {
    margin-bottom: 100px;
    padding: 0 15px 0 15px;
    .title {
      font-size: 3.77rem;
      @include questrial-font();
      margin-bottom: 15px;
    }
    .description {
      font-size: 1.5rem;
      margin-bottom: 60px;
    }
    .paper-btn {
      height: 56px;
      width: 196px;
      display: inline-block;
      margin: 8px;
    }
  }
  .graph-background {
    position: absolute;
    height: 310px;
    width: 100%;
    bottom: 0;
    background-image: url(./img/graph.png);
    background-position: bottom center;
    background-repeat: no-repeat;
  }
  .computer-continer {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: -90px;

    img {
      width: 459px;
      height: auto;
    }

    @media screen and (max-width: 500px) {
      & {
        bottom: -35px;
        img {
          width: 250px;
          height: auto;
        }
      }
    }
  }
}
