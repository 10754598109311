@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~animate.css/animate.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "styles/typography.scss";
@import "styles/variables.scss";

.custom-btn {
  border: 1px solid $turquoise-blue;
  line-height: 40px;
  border-radius: 2px;
  z-index: 1;
  color: $turquoise-blue;
  text-align: center;
  cursor: pointer;
  font-weight: bold;

  align-items: center !important;
  justify-content: center !important;
  display: inline-flex !important;

  &:hover {
    background-color: rgba(5, 170, 209, 0.12);
  }

  &.reverse {
    color: white;
    border: 1px solid white;
    &:hover {
      background-color: rgba(255, 255, 255, 0.24);
    }
  }
}

a {
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.popover {
  font-family: "Lato", sans-serif;
  line-height: normal;
}

.section-name-container {
  text-align: center;

  .section-title-container {
    .section-title {
      color: $turquoise-blue;
      font-size: 2.22rem;

      &.reverse {
        color: white;
      }
    }
  }

  .section-description-container {
    max-width: 592px;
    margin: 0 auto;
    margin-top: 28px;
    .section-description {
      font-size: 1.22rem;
      color: $turquoise-blue;

      &.reverse {
        color: white;
      }
    }
  }

  &.reverse {
    .section-index {
      color: white;
    }
    .section-deco {
      background-color: white;
    }
    .section-title {
      color: white;
    }
    .section-description {
      color: white;
    }
  }
}

.loader {
  display: inline-block;
  border: 3px solid $turquoise-blue;
  border-top: 3px solid transparent;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 2s linear infinite;

  &.reverse {
    border: 3px solid white;
    border-top: 3px solid transparent;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
